import type TrackInfo from "../../types/TrackInfo";

const {
  PINK_AZURECAST_RADIO_API_KEY,
  PINK_AZURECAST_RADIO_API_KEY_VALUE,
  PINK_METADATA_URL,
} = import.meta.env as Record<string, string>;

interface NowPlaying {
  song: TrackInfo | null;
}

interface ApiResponse {
  now_playing: NowPlaying | null;
}

export default async function fetchNowPlaying(): Promise<TrackInfo | null> {
  const controller = new AbortController();

  try {
    const metadataUrl = PINK_METADATA_URL;
    const response = await fetch(metadataUrl, {
      method: "GET",
      headers: {
        [PINK_AZURECAST_RADIO_API_KEY]: PINK_AZURECAST_RADIO_API_KEY_VALUE,
        "Content-Type": "application/json",
      },
      signal: controller.signal,
    });

    if (!response.ok) {
      throw new Error(`Error fetching metadata: ${response.statusText}`);
    }

    const data = await response.json() as ApiResponse;
    console.log(data);
    return data.now_playing?.song || null;
  } catch (error) {
    console.error("Failed to fetch metadata", error);
    return null;
  } finally {
    controller.abort();
  }
}